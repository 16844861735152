import React, { useState } from "react";
import styles from "./home.module.css";
import logoutIcon from "../../assets/icons/login-icon.png";
import locationIcon from "../../assets/icons/location-icon.png";
import homeIcon from "../../assets/icons/home-icon.png";
import globeIcon from "../../assets/icons/globe-icon.png";
import themeIcon from "../../assets/icons/theme-icon.png";
import logo from "../../assets/Group 140.png";
import bannerImage from "../../assets/header img.png";
import Footer from "../footer/Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { utils } from "../../utils/utils";
import { TailSpin } from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import whiteLogo from "../../assets/Group 3 3.png";

const jsArr = ["Anguar", "ExpressJS", "ReactJS", "VueJS" ];
const fullstackArr = [
  "Anguar + NodeJS",
  "ReactJS + ExpressJS",
  "ReactJS + Flask",
  "ReactJS + FastAPI",
];
const pyArr = ["Flask", "FastAPI"];
const phpArr = ["Laravel"];
const rArr = ["Shiny", "Plumber"];

const toastOptions = {
  toastId: "failed-scrap",
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

function Home() {

  const navigate = useNavigate();
  const email = sessionStorage.getItem("email");

  const [inProgress, setInProgress] = useState(false);
  const [projectDetails, setProjectDetails] = useState({
    link: "",
    branch: "",
    projectName: "",
    type: "",
    email,
    framework: "",
  });
  const [toggleTheme, setToggleTheme] = useState(true);
  const [invalidUrl, setInvalidUrl] = useState(false);

  const handleChange = (e, item) => {
    setProjectDetails((prev) => {
      return {
        ...prev,
        [item]: e.target.value,
      };
    });
  };

  const requestForDeployment = (e) => {
    setInProgress(true);

    axios
      .post(utils.apiUrl + "/api/sendGithubLinkMail", projectDetails)
      .then((res) => {
        console.log(res);
        if (res.data.message === "Email sent") {
          toast.success("Email sent to devops team", {
            ...toastOptions,
            autoClose: 10000,
          });
        } else {
          toast.error(
            "Failed to submit your request, please try again or contact developer",
            {
              ...toastOptions,
              autoClose: 10000,
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Server error, please contact developer", {
          ...toastOptions,
          autoClose: 10000,
        });
      })
      .finally(() => {
        setInProgress(false);
        setProjectDetails({
          link: "",
          branch: "",
          projectName: "",
          type: "fullstack",
          email,
        });
      });
  };

  const logout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  const handleTheme = () => {
    setToggleTheme(!toggleTheme);
    const body = document.querySelector("body");
    if (toggleTheme && window.location.pathname === "/home") {
      body.style.backgroundColor = "#4E4E4E";
      body.style.color = "white";
    } else {
      body.style.backgroundColor = "white";
      body.style.color = "black";
    }
  };

  const frameworkOptions = (type) => {
    if (type === "javascript") {
      return (
        <>
          {jsArr.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </>
      );
    } else if (type === "python") {
      return (
        <>
          {pyArr.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </>
      );
    } else if (type === "php") {
      return (
        <>
          {phpArr.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </>
      );
    } else if (type === "fullstack") {
      return (
        <>
          {fullstackArr.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </>
      );
    } else if (type === "r") {
      return (
        <>
          {rArr.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </>
      );
    }
  };

  const verifyUrl = (e) => {
    const url = e.target.value;
    const githubReg = new RegExp(
      /^([A-Za-z0-9]+@|https:\/\/)([A-Za-z0-9.]+(:\d+)?)(?::|\/)([\d/\w.-]+?)(\.git)?$/i
    );
    const result = githubReg.test(url);
    setInvalidUrl(!result);
  };

  return (
    <>
      <div className={styles.home}>
        <div
          className={styles.rowOne}
          style={{
            backgroundColor: toggleTheme ? "" : "#252525",
          }}
        >
          <img src={logoutIcon} alt="" />
          <p style={{ cursor: "pointer" }} onClick={logout}>
            Logout
          </p>
          {/* <img src={locationIcon} alt="" />
          <p style={{ cursor: "pointer" }}>Location</p> */}
        </div>

        <div className={styles.rowTwo}>
          <div className={styles.rowTow_logo}>
            <img
              src={toggleTheme ? logo : whiteLogo}
              className={styles.logo}
              alt=""
            />
            <p>v1.0.9</p>
          </div>
          <div className={styles.rowTwoOptions}>
            <div className={styles.iconFlex}>
            <img src={homeIcon} alt="" />
            <p>Home</p></div>
            <div className={styles.iconFlex}>
            <img src={globeIcon} alt="" />
            <p onClick={() => {navigate("/about"); window.scrollTo(0,0);}}>About</p></div>
            <div className={styles.iconFlex}>
            <img src={themeIcon} alt="" />
            <p onClick={handleTheme}>Theme</p></div>
            <div className={styles.mailIcon} title={email}>{email[0].toUpperCase()}</div>
          </div>
        </div>

        <div className={styles.rowThree}>
          <img src={bannerImage} alt="" />
        </div>

        <div
          className={styles.rowFour}
          style={{
            backgroundColor: toggleTheme ? "white" : "#252525",
            color: toggleTheme ? "black" : "white",
          }}
        >
          <form className={styles.myForm}>
            <label htmlFor="url">
              Enter GitHub URL:{" "}
              {invalidUrl && (
                <span className={styles.errorUrl}>
                  *provide a valid github url
                </span>
              )}{" "}
            </label>
            <input
              type="url"
              name="url"
              id="url"
              placeholder="Paste GitHub URL"
              required
              value={projectDetails.link}
              onChange={(e) => handleChange(e, "link")}
              onKeyUp={(e) => verifyUrl(e)}
            />

            <label htmlFor="branch">GitHub branch name:</label>
            <input
              type="text"
              name="branch"
              id="branch"
              placeholder="Enter GitHub branch name"
              required
              value={projectDetails.branch}
              onChange={(e) => handleChange(e, "branch")}
            />

            <label htmlFor="project">Name of the project:</label>
            <input
              type="text"
              name="project"
              id="project"
              placeholder="Enter name of the project"
              required
              value={projectDetails.projectName}
              onChange={(e) => handleChange(e, "projectName")}
            />

            <div className={styles.dType_box}>
              
                {/* <label htmlFor="dtype">Select deployment type</label> */}
                <select
                  name="dtype"
                  id="dtype"
                  className={styles.dType}
                  value={projectDetails.type}
                  onChange={(e) => handleChange(e, "type")}
                >
                  <option value="" selected hidden>Select deployment type</option>
                  <option value="fullstack">Full Stack</option>
                  <option value="javascript" >JavaScript</option>
                  <option value="python">Python</option>
                  <option value="php">PHP</option>
                  <option value="r">R</option>
                </select>
             

              
                {/* <label htmlFor="dtype" style={{ marginTop: "15px" }}>
                  Select framework
                </label> */}
                <select
                  name="dtype"
                  id="dtype"
                  className={styles.dType}
                  value={projectDetails.framework}
                  onChange={(e) => handleChange(e, "framework")}
                >
                  <option selected hidden value="">Select framework</option>
                   {frameworkOptions(projectDetails.type)}
                </select>
            </div>


            <button
              type="button"
              className={styles.submit}
              onClick={requestForDeployment}
              disabled={
                inProgress ||
                !projectDetails.link ||
                !projectDetails.branch ||
                !projectDetails.projectName ||
                !projectDetails.type ||
                !projectDetails.framework ||
                invalidUrl
              }
            >
              Request Deployment &nbsp;
              {inProgress ? (
                <TailSpin
                  height="25"
                  width="25"
                  color="#ffffff"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                ""
              )}
            </button>
          </form>
        </div>
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
}

export default Home;
