import React, { useState } from "react";
import styles from "../login/login.module.css";
import logo from "../../assets/slicearrowlogo.png";
import codingamico from "../../assets/Coding-amico1.png";
import sirpiLogo from "../../assets/Sirpi LOGO 2.png";
import axios from "axios";
import { utils } from "../../utils/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const toastOptions = {
  toastId: "failed-scrap",
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [inProgress, setInProgress] = useState(false);

  const navigate = useNavigate();

  const login = (e) => {
    e.preventDefault();

    if (password !== cPassword) {
      toast.error("Passwords should match", {
        ...toastOptions,
        autoClose: 10000,
      });
      return null;
    }
    setInProgress(true);

    const state = {
      email,
      password,
    };
    axios
      .post(utils.apiUrl + "/api/signup", state)
      .then((res) => {
        console.log(res);
        if (res.data.email) {
          //user created
          toast.success("User created successfully, redirecting...", {
            ...toastOptions,
            autoClose: 5000,
          });
          sessionStorage.setItem("email", res.data.email);

          setTimeout(() => {
            navigate("/redirect");
          }, 3000);
        } else if (res.data.error === "Conflict") {
          // user exists
          toast.error("This email is already in use", {
            ...toastOptions,
            autoClose: 10000,
          });
        } else if (res.data.error === "Bad Request") {
          // not valid email format

          if (res.data.message.includes("Payload")) {
            toast.error("Please provide a valid email", {
              ...toastOptions,
              autoClose: 10000,
            });
          } else {
            toast.error(res.data.message, {
              ...toastOptions,
              autoClose: 10000,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Server error, please contact developer", {
          ...toastOptions,
          autoClose: 10000,
        });
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  return (
    <>
      <div className={styles.login}>
        <div className={styles.login_logobox}>
          <img src={logo} alt="slicearrowlogo" className={styles.logo} />
        </div>

        <div className={styles.main}>
          <div className={styles.main_imgbox}>
            <img
              src={codingamico}
              alt="coding-anico"
              className={styles.main_img}
            />
            <div className={styles.main_text}>
              {/* <span>
                Lorem Ipsum is simply dummy text of the printing and type aa aa
                aa setting industry. Lorem Ipsum has been
              </span> */}
            </div>
          </div>
          <form className={styles.login_form}>
            <div>
              <p className={styles.login_form__text}>
                Already have an account? &nbsp;
                <a href="/" className={styles.login_form_link}>
                  Log In
                </a>
              </p>
            </div>
            <div className={styles.login_text}>
              <span>Sign Up</span>
            </div>
            <div className={styles.input_field}>
              <p className={styles.label}>Email</p>
              <input
                type="email"
                placeholder="Enter Your Email"
                className={styles.input_container}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className={styles.input_field}>
              <p className={styles.label}>Password</p>
              <input
                type="password"
                placeholder="Enter Your Password"
                className={styles.input_container}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className={styles.input_field}>
              <p className={styles.label}>Confirm Password</p>
              <input
                type="password"
                placeholder="Confirm Your Password"
                className={styles.input_container}
                value={cPassword}
                onChange={(e) => setCPassword(e.target.value)}
                required
              />
            </div>
            <button
              disabled={inProgress || !email || !password || !cPassword}
              type="submit"
              className={styles.login_btn}
              onClick={login}
            >
              Sign Up &nbsp;
              {inProgress ? (
                <TailSpin
                  height="25"
                  width="25"
                  color="#ffffff"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                ""
              )}
            </button>

            <div className={styles.footer}>
              <span className={styles.footer_text}>Powered by:</span>
              <img
                src={sirpiLogo}
                alt="sirpiLogo"
                className={styles.footer_img}
              />
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Signup;
