import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Redirect() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 10000);
  }, []);

  return (
    <div style={{ textAlign: "center", paddingTop: "5%" }}>
      <h2>
        We've send you a verification email. Please wait for 5 minutes and<br />
        Please check your inbox or spam and verify.
      </h2>
      <h2 style={{ marginTop: "20px" }}>Login after verifying your email.</h2>
      <h4>Redirecting to Login page...</h4>
    </div>
  );
}

export default Redirect;
