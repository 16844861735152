import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/login/Login";
import Home from "./components/home/Home";
import Maintenance from "./components/maintenance/Maintenance";
import Signup from "./components/signup/Signup";
import Redirect from "./components/redirect/Redirect";
import About from "./components/about/About";

function App() {
  const email = sessionStorage.getItem("email");

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="*" element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          {email && <Route path="/home" element={<Home />} />}
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/redirect" element={<Redirect />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
