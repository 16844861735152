import React, {useEffect, useState} from "react";
import logoutIcon from "../../assets/icons/login-icon.png";
import styles from './about.module.css';
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Group 140.png";
import whiteLogo from "../../assets/Group 3 1.png";
import globeIcon from "../../assets/icons/globe-icon.png";
import homeIcon from "../../assets/icons/home-icon.png";
import themeIcon from "../../assets/icons/theme-icon.png";
import bannerImage from "../../assets/header img.png";
import headerImage from "../../assets/Group 157.png";
import slicearrowvid from "../../assets/slicearrow.mp4";
import stepsImage2 from "../../assets/Group 161.png";
import sa1 from "../../assets/sa1.png";
import sa2 from "../../assets/sa2.png";
import sa3 from "../../assets/sa3.png";
import Footer from "../footer/Footer";




const About = () => {
    const navigate = useNavigate();
    const email = sessionStorage.getItem("email");

    const handleTheme = () => {
      const body = document.querySelector("body");
      if (window.location.pathname === "/about") {
        body.style.backgroundColor = "white";
        body.style.color = "black";
      }
    };

    useEffect(() => {
      handleTheme();
    }, []);

    const logout = () => {
        sessionStorage.clear();
        navigate("/");
    };

    return (
        <div className={styles.about}>
        <div
          className={styles.rowOne}
          // style={{
          //   backgroundColor: toggleTheme ? "" : "#252525",
          // }}
        >
          <img src={logoutIcon} alt="" />
          <p style={{ cursor: "pointer" }} onClick={logout}>
            Logout
          </p>
          {/* <img src={locationIcon} alt="" />
          <p style={{ cursor: "pointer" }}>Location</p> */}
        </div>

        <div className={styles.rowTwo}>
        <div className={styles.rowTow_logo}>
            <img
              src={logo}
              className={styles.logo}
              alt=""
            />
            <p>v1.0.9</p>
          </div>
          <div className={styles.rowTwoOptions}>
            <img src={homeIcon} alt="" />
            <p onClick={() => {navigate("/home"); window.scrollTo(0,0)}}>Home</p>
            <img src={globeIcon} alt="" />
            <p>About</p>
            {/* <img src={themeIcon} alt="" />
            <p onClick={handleTheme}>Theme</p> */}
            <div title={email}>{email[0].toUpperCase()}</div>
          </div>
        </div>

        <div className={styles.rowThree}>
          <img src={bannerImage} alt="banner"/>
        </div> 

          <div className={styles.main}>
            <div className={styles.abtus}>
                <div className={styles.vector1}></div>
                <div className={styles.main_heading}>
                    <h1 className={styles.main_heading__h1}>about us</h1>
                </div>
                <div className={styles.main_heading_box}>
                    <div className={styles.main_heading_box__content}>
                        <h3 className={styles.main_heading_box__content__heading}>Slice Arrow is a platform, which provides deployment service for the source code along with that it also provide devops support for client.</h3>
                        {/* <p className={styles.main_heading_box__content__text}>Slice arrow is a platform, which provides deployment service for the source code along with that it also provide devops support for client. </p> */}
                    </div>

                    <div className={styles.main_heading_box__img}>
                      <img src={headerImage} alt="headerImage" />
                    </div>
                </div>

                <div className={styles.main_middle_box}>
                  <div className={styles.main_middle_box_heading}>
                    <h1 className={styles.main_middle_box_heading__h1}>how slice arrow works</h1>
                  </div>
                  <div className={styles.main_middle_box_img}>
                    <div className={styles.main_middle_box1}>
                      <video className={styles.main_middle_box_video} controls>
                        <source src={slicearrowvid} type="video/ogg" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <div className={styles.main_middle_box2}>
                      <img className={styles.main_middle_box_img2} src={stepsImage2} alt="stepsImage2" />
                    </div>
                  </div>
                </div>

                <div className={styles.main_faqs}>
                  <div className={styles.main_middle_box_heading}>
                    <h1 className={styles.main_middle_box_heading__h1}>faq's</h1>
                  </div>
                  <details>
                    <summary>1. How to create a GitHub account?</summary>
                    <hr />
                    <div className={styles.details_content}>
                      <ul>
                        <li>Go to <a href="https://github.com/join" target="_blank">https://github.com/join</a></li>
                        <li>Type a user name, your email address, and a password.</li>
                        <li>Choose Sign up for GitHub, and then follow the instructions.</li>
                      </ul>
                    </div>
                  </details>
                  
                  <details>
                    <summary>2. How to create a GitHub repository?</summary>
                    <hr />
                    <div className={styles.details_content}>
                      <ul>
                        <li>In the upper-right corner of any page, use the  drop-down menu, and select New repository.</li>
                        <li style={{listStyle: 'none'}}><img src={sa1} alt="git image" /></li>
                        <li>Drop-down with option to create a new repository</li>
                        <li>In the Repository name box, enter hello-world.</li>
                        <li>In the Description box, write a short description.</li>
                        <li>Select Add a README file.</li>
                        <li>Select whether your repository will be Public or Private.</li>
                        <li>Click Create repository.</li>
                        <li style={{listStyle: 'none'}}><img src={sa2} alt="gitimage" width="50%"/></li>
                      </ul>
                    </div>
                  </details>
                  
                  <details>
                    <summary>3. How to create a GitHub branch?</summary>
                    <hr />
                    <div className={styles.details_content}>
                      <ul>
                        <li>Create a branch</li>
                        <li>Click the Code tab of your hello-world repository.</li>
                        <li>Click the drop down at the top of the file list that says main.</li>
                        <li style={{listStyle: 'none'}}><img src={sa3} alt="gitimage" className={styles.detailsPicture} /></li>
                        <li>Branch menu</li>
                        <li>Type a branch name, readme-edits, into the text box.</li>
                        <li>Click Create branch: readme-edits from main.</li>
                      </ul>
                    </div>
                  </details>

                  <details>
                    <summary>4. Github cheatsheet</summary>
                    <hr />
                    <div className={styles.details_content}>
                      <ul>
                        <li>click <a href="https://education.github.com/git-cheat-sheet-education.pdf" target="_blank">here</a> to download cheet sheet</li>
                      </ul>
                    </div>
                  </details>
                </div> 

                <div className={styles.lgs_box}>
                  <div className={styles.main_middle_box_heading}>
                      <h1 className={styles.main_middle_box_heading__faq}>Let's get started!</h1>
                  </div>
                  <div className={styles.lgs_button}>
                    <p onClick={() => {navigate("/home"); window.scrollTo(0,0);}} className={styles.lgs_button__home}>Go to Home</p>
                  </div>
                  <div className={styles.vector2}></div>
                </div>
            </div>
          </div>
            <div>
              <Footer />
            </div>
        </div>
    )
}

export default About;