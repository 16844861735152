import React, { useState } from "react";
import styles from "./login.module.css";
import logo from "../../assets/Group 140.png";
import codingamico from "../../assets/Coding-amico1.png";
import sirpiLogo from "../../assets/Sirpi LOGO 2.png";
import axios from "axios";
import { utils } from "../../utils/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";

const toastOptions = {
  toastId: "failed-scrap",
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [inProgress, setInProgress] = useState(false);

  const login = (e) => {
    e.preventDefault();
    setInProgress(true);

    const state = {
      username: email,
      password: password,
    };
    axios
      .post(utils.apiUrl + "/api/login", state)
      .then((res) => {
        console.log(res);
        if (res.data.message === "User logged in") {
          sessionStorage.setItem("email", email);
          window.location.href = "/home";
        } else if (res.data.message === "Email not verified") {
          toast.error(
            "You have not verified your email, please check your mail.",
            {
              ...toastOptions,
              autoClose: 10000,
            }
          );
        } else {
          toast.error(res.data.message, {
            ...toastOptions,
            autoClose: 10000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Server error, please contact developer", {
          ...toastOptions,
          autoClose: 10000,
        });
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  return (
    <>
      <div className={styles.login}>
        <div className={styles.login_logobox}>
          <div className={styles.login_logobox1}>
            <img src={logo} alt="slicearrowlogo" className={styles.logo} />
            <p>v1.0.9</p>
          </div>
        </div>

        <div className={styles.main}>
          <div className={styles.main_imgbox}>
            <img
              src={codingamico}
              alt="coding-anico"
              className={styles.main_img}
            />
            <div className={styles.main_text}>
              {/* <span>
                Lorem Ipsum is simply dummy text of the printing and type aa aa
                aa setting industry. Lorem Ipsum has been
              </span> */}
            </div>
          </div>
          <form className={styles.login_form}>
            <div className={styles.logindiv}>
            <div>
              <p className={styles.login_form__text}>
                Don't have an account?{" "}
                <a href="/signup" className={styles.login_form_link}>
                  Sign up
                </a>
              </p>
            </div>
            <div className={styles.login_text}>
              <span>Log in</span>
            </div>
            <div className={styles.input_field}>
              <p className={styles.label}>Email</p>
              <input
                type="email"
                placeholder="Enter Your Email"
                className={styles.input_container}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className={styles.input_field}>
              <p className={styles.label}>Password</p>
              <input
                type="password"
                placeholder="Enter Your Password"
                className={styles.input_container}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {/* <p className={styles.text2}>
                Forgot your password?{" "}
                <a className={styles.password_link} href="#/">
                  Click here
                </a>
              </p> */}
            </div>
            <button
              disabled={inProgress || !email || !password}
              type="submit"
              className={styles.login_btn}
              onClick={login}
            >
              Log in &nbsp;
              {inProgress ? (
                <TailSpin
                  height="25"
                  width="25"
                  color="#ffffff"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                ""
              )}
            </button>
            </div>
            <div className={styles.footer}>
              <span className={styles.footer_text}>Powered by:</span>
              <img
                src={sirpiLogo}
                alt="sirpiLogo"
                className={styles.footer_img}
              />
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Login;
