import React from "react";
import styles from "./maintenance.module.css";

function Maintenance() {
  return (
    <div className={styles.container}>
      <div className={styles.rowOne}>
        <img src="/maintenance/logo.png" alt="" />
      </div>

      <div className={styles.rowTwo}>
        <img src="/maintenance/illustration.png" alt="" />
        <h3>We‘re improving your experience </h3>
        <h5>
          Slice Arrow is currently down for maintenance. We expect to be back
          shortly.
        </h5>
      </div>
    </div>
  );
}

export default Maintenance;
