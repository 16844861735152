import React, {useState} from "react";
import styles from "./footer.module.css";
import sliceArrowLogo from "../../assets/Group 3 3.png";
import sirpiLogo from "../../assets/Sirpi LOGO 1.png";
import { useNavigate } from "react-router-dom";


function Footer() {
  const navigate = useNavigate();

  return (
    <div className={styles.footer}>
      <div className={styles.footer_logo_box}>
        <div>
          <img
            src={sliceArrowLogo}
            alt="sliceArrow_logo"
            className={styles.footer_logo}
          />
          <p>v1.0.9</p>
        </div>
      </div>

      <div className={styles.footer_main}>
        <div className={styles.footer_navigation}>
          <a onClick={() => {navigate("/home"); window.scrollTo(0,0);}} className={styles.footer_link}>
            Home
          </a>
          <a onClick={() => {navigate("/about"); window.scrollTo(0,0);}} className={styles.footer_link}>
            About
          </a>
          {/* <p className={styles.footer_link}>
            Theme
          </p> */}
        </div>
        <div className={styles.sirpi_logo}>
          <p className={styles.sirpi_logo_txt}>Powered by:</p>
          <img src={sirpiLogo} alt="sirpiLogo" />
        </div>
        <div className={styles.footer_address_box}>
          <div className={styles.footer_address}>
            <p>&copy;2018 BY SIRPI PRODUCTS AND SERVICES PRIVATE LIMITED.</p>

            <p>All Rights Reserved</p>
            <p className={styles.footer_policy}>Privacy Policy</p>
          </div>
        </div>
      </div>

      <div className={styles.footer_text_box}>
        {/* <p className={styles.footer_text}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                </p> */}
      </div>
    </div>
  );
}

export default Footer;
