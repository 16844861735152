// backend dev link
 const apiUrl = "https://backend-devv.slicearrow.com";
// const apiUrl = "http://localhost:8000";

//const apiUrl = "";

// backend prod link
// const apiUrl = "https://api.slicearrow.com"

export const utils = {
  apiUrl,
};
